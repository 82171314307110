import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import cyan from '@material-ui/core/colors/cyan';

let theme = createMuiTheme({
  palette: {
    primary: {
      main: cyan[600],
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: [
      'Overpass',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: '50px',
        fontFamily: [
          '"Segoe UI"',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
        ].join(','),
      },
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
